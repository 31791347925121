<script>
import Layout from "../../layouts/main";

import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      limit: 20,
      page: 1,
      matchedProducts:[],
      products: [],
      accountFilter: {
        from: "",
        to: "",
      },
      search: "",
      selected_product: {},
      totalPrice: 0,
      selected_agent: {},
      type: 'sale',
      suppliers: [],
      customers: [],
      user: [],

    };
  },
  methods: {
    getUser() {
      this.http.post("users/current-user",{}).then((res) => {
        this.user = res.data;
      });
    },
    getProductReport() {
      console.log({ accountFilter: this.accountFilter });
      console.log('selected_product',this.selected_product);
      this.http
          .post("products/product-report", {
            product_id: this.selected_product.id,
            agent_id: this.selected_agent.id,
            accountFilter: this.accountFilter,
            type: this.type,
          })
          .then((res) => {
            res.products.forEach(item=>item.discount = item.discount? true : false)
            this.products = res.products;
            console.log('this.products', this.products);
            if(res.status && res.products.length==0){
              this.popup.alert({
                title: "popups.error",
                msg: this.$t("reports.no_products_data")
              });
            }
            else if (res.status) {
              this.totalPrice = 0;
              this.products.forEach((product) => {
                this.totalPrice += !product?.discount ? (parseFloat(product?.weight) * parseFloat(product?.sale_price)) : ((parseFloat(product?.weight) - parseFloat(product?.number)) * parseFloat(product?.sale_price))
              });
            }
          });
    },
    searchProduct(search) {
      this.http
          .post("products/search-in-supplier", {
            search,
            existingProducts:[],//this.purchase_order.items.filter(i=>typeof(i.product)=='object').map(i=>i.product.id)
          })
          .then((res) => {
            if (res.status) {
              this.matchedProducts = res.products
            }
          });
    },
    preChoose(){
      this.selected_agent = {}
    },
    supplierSearch(search) {
      this.http
        .post("purchases-agents/search", {
          search,
        })
        .then((res) => {
          if (res.status) {
            this.suppliers = res.data
          }
        });
    },
    customerSearch(search) {
      this.http
        .post("sales-agents/search", {
          search,
        })
        .then((res) => {
          if (res.status) {
            this.customers = res.data
          }
        });
    },
    showSupplierLabel(one) {
      return one.code + '-' + one.name;
    },
    showLabel(one) {
      return this.$i18n.locale == 'ar'? one.name : one.name_en;
    },
    printReport() {
      window.print();
    },
  },
  computed: {

  },
  created() {
this.getUser()
  },
};
</script>

<template>
  <Layout class="no-print">
    <PageHeader
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        :title="$t('reports.product_report')"
    />

    <div class="row">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="getProductReport()">
            <div class="p-3" style="background-color: rgb(246, 247, 248)">
              <div class="row">
                <!-- Type Sale or Purchase -->
                <div class="col-12 col-md-4">
                  <label for="" class="d-block">{{$t('popups.type')}}</label>
                  <select v-model="type" class="form-select mb-2" @click="preChoose()">
                    <option value="sale">{{$t("reports.sale_report")}}</option>
                    <option value="purchase">{{$t("reports.purchase_report")}}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-md-4">
                  <label for="" class="col-md-2.5 col-form-label">{{
                      $t("reports.select_product")
                    }}</label>
                  <AutoComplete
                      v-model="selected_product"
                      :suggestions="matchedProducts"
                      :complete-on-focus="true"
                      @complete="searchProduct($event.query)"
                      :optionLabel="showLabel"
                      style="color: #333 !important;width:100%"
                  />
                </div>
                <!-- <div class="col-3">

                </div> -->
              
                <!-- Supplier -->
                <div class="col-12 col-md-4" v-if="type == 'purchase'">
                  <label for="" class="col-md-2.5 col-form-label">{{$t('purchases.select_supplier')}}</label>
                  <AutoComplete
                      v-model="selected_agent"
                      :suggestions="suppliers"
                      @complete="supplierSearch($event.query)"
                      :complete-on-focus="true"
                      :optionLabel="showSupplierLabel"
                      style="color: #333 !important;width:100%" />
                </div>
                <!-- Customer -->
                <div class="col-12 col-md-4" v-if="type == 'sale'">
                  <label for="" class="col-md-2.5 col-form-label">{{$t('sales.select_customer')}}</label>
                  <AutoComplete
                      v-model="selected_agent"
                      :suggestions="customers"
                      @complete="customerSearch($event.query)"
                      :complete-on-focus="true"
                      :optionLabel="showSupplierLabel"
                      style="color: #333 !important;width:100%" />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col d-inline">
                  <label for="" class="d-block">{{ $t("reports.from") }}</label>
                  <input
                      v-model="accountFilter.from"
                      type="date"
                      class="form-control"
                  />
                </div>
                <div class="col d-inline">
                  <label for="" class="d-block">{{ $t("reports.to") }}</label>

                  <input
                      v-model="accountFilter.to"
                      type="date"
                      class="form-control"
                  />
                </div>
                <div class="col d-flex align-items-end">
                  <button
                      style="height: 40px"
                      class="btn btn-success"
                  >
                    {{ $t("popups.search") }}
                  </button>
                </div>

              </div>
            </div>
          </form>

          <div v-if="products.length > 0">
            <br />
            <button class="btn btn-success float-end" @click="printReport()">
              <i class="bx bx-printer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" v-if="products.length > 0">
      <div class="card card-body">
        <ReportsHeader v-if="type == 'sale'"
            :reportName= "$t('reports.products_statement_sale_pre') +' '+ ($i18n.locale == 'en' ? (selected_product?.name_en ? selected_product?.name_en : '-') : (selected_product?.name ? selected_product?.name : '-')) +' '+ $t('reports.products_statement_sale_after') +' '+ (selected_agent?.name ? selected_agent?.name : '-')"
            :accountNo="selectedSupplier?.id"
            :accountName="products[0]?.name"
        />
        <ReportsHeader v-if="type == 'purchase'"
        :reportName= "$t('reports.products_statement_purchase_pre') +' '+ ($i18n.locale == 'en' ? (selected_product?.name_en ? selected_product?.name_en : '-') : (selected_product?.name ? selected_product?.name : '-')) +' '+ $t('reports.products_statement_purchase_after') +' '+ (selected_agent?.name ? selected_agent?.name : '-')"
        :accountNo="selectedSupplier?.id"
            :accountName="products[0]?.name"
        />

        <div class="table-responsive">
          <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
          >
            <thead>
            <tr
                class="text-light"
                style="background-color: #2a3042 !important"
            >
              <th scope="col">#</th>
              <th scope="col">{{ $t("products_report.number") }}</th>
              <th scope="col">{{ $t("products_report.driver") }}</th>
              <th scope="col">{{ $t("products_report.plate_num") }}</th>
              <th scope="col" v-if="type == 'sale'">{{ $t("products_report.sale_price") }}</th>
              <th scope="col">{{ $t("products_report.gross_weight") }}</th>
              <th scope="col">{{ $t("products_report.weight") }}</th>
              <th scope="col" v-if="type == 'purchase'">{{ $t("purchases.transport") }}</th>
              <th scope="col" v-if="type == 'sale'">{{ $t("sales.owner") }}</th>
              <th scope="col">{{ $t("products_report.type") }}</th>
              <th scope="col" v-if="type == 'purchase'">{{ $t("sales.distnation") }}</th>
              <th scope="col">{{ $t("products_report.date") }}</th>
              <th scope="col">{{ $t("products_report.total_price") }}</th>
            </tr>
            </thead>
            <tbody>
            <tr
                class=""
                v-for="product in products"
                :key="product"
            >
              <td># {{ product?.id }}</td>
              <td>{{ parseFloat(product?.number ? product?.number : 0).toLocaleString() }}</td>
              <td>{{ product?.driver ? product?.driver : '-' }}</td>
              <td>{{ product?.plate_num ? product?.plate_num : '-' }}</td>
              <td v-if="type == 'sale'">{{ product?.sale_price }}</td>
              <td>{{ parseFloat(product?.qty).toLocaleString() }}</td>
              <td>{{ product?.weight ? parseFloat(product?.weight).toLocaleString() : '-' }}</td>
              <td v-if="type == 'purchase'">{{ product?.transport? product?.transport : 0 }}</td>
              <td v-if="type == 'sale'">{{ product?.owner }}</td>
              <td>{{ product?.name ? product?.name : '-' }}</td>
              <td v-if="type == 'purchase'">{{ product?.distnation ? product?.distnation : '-' }}</td>
              <td :title="moment(product?.created).format('h:mm a')">{{ moment(product?.created).format("YYYY-MM-DD") }}</td>
              <td>{{ Math.round(!product?.discount ? (parseFloat(product?.weight) * parseFloat(product?.sale_price)) : ((parseFloat(product?.weight) - parseFloat(product?.number)) * parseFloat(product?.sale_price))) }}</td>
              <!-- <td>{{ product?.discount ==0 ? (parseFloat(product?.qty) * parseFloat(product?.sale_price)) + (type == 'purchase' ? parseFloat(product?.landed_cost) : 0 ) : ((parseFloat(product?.qty) - parseFloat(product?.number)) * parseFloat(product?.sale_price)) + (type == 'purchase' ? parseFloat(product?.landed_cost) : 0 ) }}</td> -->

            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td v-if="type == 'sale'"></td>
              <td v-if="type == 'purchase'"></td>
              <td v-if="type == 'sale'"></td>
              <td v-if="type == 'purchase'"></td>
              <td></td>
              <td></td>
              <td>{{ $t("products_report.total") }}</td>
              <td class="total-style">
                {{ parseFloat(Math.round(totalPrice)).toLocaleString() }}
              </td>
              <!-- <td class="total-style">
                {{ parseFloat(totalCredit).toLocaleString() }}
              </td> -->
              <!-- <td class="total-style">
                {{0 > (parseFloat(totalCredit) - parseFloat(totalDebit)) ? "(":""}}
                {{
                  (
                      parseFloat(totalCredit) - parseFloat(totalDebit)
                  ).toLocaleString()
                }}
                {{0 > (parseFloat(totalCredit) - parseFloat(totalDebit)) ? ")":""}}

              </td> -->
            </tr>
            </tbody>
          </table>
          <table style="margin: 30px;">
            <thead>
            <th>{{ $t("reports.report_date") }} :</th>
            <th>
              {{
                `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()},
                 ${new Date().getHours()}:${new Date().getMinutes()}`
              }}
            </th>
            <th style="padding: 0 150px;">
              {{ $t("products_report.user")+ ' : ' + user.name}}
            </th>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </Layout>

  <div class="row mt-4 print" v-if="products.length > 0">
    <div class="card card-body">
        <ReportsHeader v-if="type == 'sale'"
            :reportName= "$t('reports.products_statement_sale_pre') +' '+ ($i18n.locale == 'en' ? (selected_product?.name_en ? selected_product?.name_en : '-') : (selected_product?.name ? selected_product?.name : '-')) +' '+ $t('reports.products_statement_sale_after') +' '+ (selected_agent?.name ? selected_agent?.name : '-')"
            :accountNo="selectedSupplier?.id"
            :accountName="products[0]?.name"
        />
        <ReportsHeader v-if="type == 'purchase'"
        :reportName= "$t('reports.products_statement_purchase_pre') +' '+ ($i18n.locale == 'en' ? (selected_product?.name_en ? selected_product?.name_en : '-') : (selected_product?.name ? selected_product?.name : '-')) +' '+ $t('reports.products_statement_purchase_after') +' '+ (selected_agent?.name ? selected_agent?.name : '-')"
        :accountNo="selectedSupplier?.id"
            :accountName="products[0]?.name"
        />

        <div class="table-responsive">
          <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
          >
            <thead>
            <tr
                class="text-light"
                style="background-color: #2a3042 !important"
            >
              <th scope="col">#</th>
              <th scope="col">{{ $t("products_report.number") }}</th>
              <th scope="col">{{ $t("products_report.driver") }}</th>
              <th scope="col">{{ $t("products_report.plate_num") }}</th>
              <th scope="col" v-if="type == 'sale'">{{ $t("products_report.sale_price") }}</th>
              <th scope="col">{{ $t("products_report.gross_weight") }}</th>
              <th scope="col">{{ $t("products_report.weight") }}</th>
              <th scope="col" v-if="type == 'purchase'">{{ $t("purchases.transport") }}</th>
              <th scope="col" v-if="type == 'sale'">{{ $t("sales.owner") }}</th>
              <th scope="col">{{ $t("products_report.type") }}</th>
              <th scope="col" v-if="type == 'purchase'">{{ $t("sales.distnation") }}</th>
              <th scope="col">{{ $t("products_report.date") }}</th>
              <th scope="col">{{ $t("products_report.total_price") }}</th>
            </tr>
            </thead>
            <tbody>
            <tr
                class=""
                v-for="product in products"
                :key="product"
            >
              <td># {{ product?.id }}</td>
              <td>{{ parseFloat(product?.number ? product?.number : 0).toLocaleString() }}</td>
              <td>{{ product?.driver ? product?.driver : '-' }}</td>
              <td>{{ product?.plate_num ? product?.plate_num : '-' }}</td>
              <td v-if="type == 'sale'">{{ product?.sale_price }}</td>
              <td>{{ parseFloat(product?.qty).toLocaleString() }}</td>
              <td>{{ product?.weight ? parseFloat(product?.weight).toLocaleString() : '-' }}</td>
              <td v-if="type == 'purchase'">{{ product?.transport? product?.transport : 0 }}</td>
              <td v-if="type == 'sale'">{{ product?.owner }}</td>
              <td>{{ product?.name ? product?.name : '-' }}</td>
              <td v-if="type == 'purchase'">{{ product?.distnation ? product?.distnation : '-' }}</td>
              <td :title="moment(product?.created).format('h:mm a')">{{ moment(product?.created).format("YYYY-MM-DD") }}</td>
              <td>{{ Math.round(!product?.discount ? (parseFloat(product?.weight) * parseFloat(product?.sale_price)) : ((parseFloat(product?.weight) - parseFloat(product?.number)) * parseFloat(product?.sale_price))) }}</td>
              <!-- <td>{{ product?.discount ==0 ? (parseFloat(product?.qty) * parseFloat(product?.sale_price)) + (type == 'purchase' ? parseFloat(product?.landed_cost) : 0 ) : ((parseFloat(product?.qty) - parseFloat(product?.number)) * parseFloat(product?.sale_price)) + (type == 'purchase' ? parseFloat(product?.landed_cost) : 0 ) }}</td> -->

            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td v-if="type == 'sale'"></td>
              <td v-if="type == 'purchase'"></td>
              <td v-if="type == 'sale'"></td>
              <td v-if="type == 'purchase'"></td>
              <td></td>
              <td></td>
              <td>{{ $t("products_report.total") }}</td>
              <td class="total-style">
                {{ parseFloat(Math.round(totalPrice)).toLocaleString() }}
              </td>
              <!-- <td class="total-style">
                {{ parseFloat(totalCredit).toLocaleString() }}
              </td> -->
              <!-- <td class="total-style">
                {{0 > (parseFloat(totalCredit) - parseFloat(totalDebit)) ? "(":""}}
                {{
                  (
                      parseFloat(totalCredit) - parseFloat(totalDebit)
                  ).toLocaleString()
                }}
                {{0 > (parseFloat(totalCredit) - parseFloat(totalDebit)) ? ")":""}}

              </td> -->
            </tr>
            </tbody>
          </table>
          <table style="margin: 30px;">
            <thead>
            <th>{{ $t("reports.report_date") }} :</th>
            <th>
              {{
                `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()},
                 ${new Date().getHours()}:${new Date().getMinutes()}`
              }}
            </th>
            <th style="padding: 0 150px;">
              {{ $t("products_report.user")+ ' : ' + user.name}}
            </th>
            </thead>
          </table>
        </div>
      </div>
  </div>
</template>

<style scoped>
/*
.table,
td {
  border: 1px solid black;
}
*/
.print {
  display: none;
}
@media print {
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }
}
</style>
